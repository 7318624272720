<template>
  <div class="project-comparison">
    <router-view
      :projectId="projectId"
      :decisionId="decisionId"
    />
  </div>
</template>

<script>
export default {
  data () {
    return {
      projectId: this.$route.query.projectId,
      decisionId: this.$route.query.decisionId
    }
  },
  mounted () {
    // console.log('projectId', this.projectId)
    // console.log('decisionId', this.decisionId)
  }
}
</script>

<style lang="stylus" scoped>
.project-comparison>>>
  .details-no-data
    height 300px
    overflow hidden
    margin 0
    background #272930
    padding 180px
</style>
